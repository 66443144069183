import React, { useEffect } from 'react';

import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';

import ResetSection from '../components/ResetSection';

const ResetPasswordPage = ({ location, path }) => {
  const [menuIsShown, showMenu] = React.useState(false);

  const params = new URLSearchParams(location?.search);
  const code = params.get('code');

  useEffect(() => {
    if (!code) window.location.replace('/');
  }, [code]);

  return (
    <main>
      <SEO title="Reset password"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />
      <ResetSection showMenu={showMenu} code={code}></ResetSection>
      <Footer menuIsShown={menuIsShown} path={path} />
    </main>
  );
};

export default ResetPasswordPage;
