import React from 'react';

import ResetPasswordForm from '../ResetPasswordForm';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 150px;
  margin-bottom: 100px;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 20rem;
`;

const ResetTitle = styled.h1`
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
  margin-top: 0;
`;

const ResetSection = ({ code, showMenu }) => {
  return (
    <Container>
      <Content>
        <ResetTitle>Create a new password</ResetTitle>
        <ResetPasswordForm code={code} showMenu={showMenu}></ResetPasswordForm>
      </Content>
    </Container>
  );
};

export default ResetSection;
