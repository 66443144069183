import React, { useState } from 'react';
import { useFormik } from 'formik';

import { resetPassword } from '../../lib/auth';

import TextInput from '../TextInput';

import Form from '../Form';

import { ErrorMessage, Button } from '../ForgotPasswordForm/styles';

import { validationSchema } from './validations';

const STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const ResetPasswordForm = ({ code, showMenu }) => {
  const [status, setStatus] = useState(STATES.IDLE);

  const formik = useFormik({
    initialValues: {
      code: code,
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log('SUBMIT');
        setStatus(STATES.LOADING);
        await resetPassword(values);
        setStatus(STATES.SUCCESS);
      } catch (err) {
        console.log({ err });
        setStatus(STATES.ERROR);
      }
    },
  });

  const resetState = () => {
    setStatus(STATES.IDLE);
  };

  return (
    <div style={{ minHeight: '18rem' }}>
      {status === STATES.IDLE ? (
        <Form formik={formik} submitText="Change password">
          <label htmlFor="password">Define a new password</label>
          <TextInput
            type="password"
            id="password"
            name="password"
            autoComplete="new-password"
            placeholder="New password"
            value={formik.values.password}
            onChange={formik.handleChange}
            errorMessage={formik.errors?.password}
          />
          <label htmlFor="passwordConfirmation">Repeat your new password</label>
          <TextInput
            type="password"
            id="passwordConfirmation"
            name="passwordConfirmation"
            autoComplete="new-password"
            placeholder="Repeat new password"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            errorMessage={formik.errors?.passwordConfirmation}
          />
          <input type="hidden" id="code" name="code" defaultValue={formik.values.code} />
        </Form>
      ) : null}
      {status === STATES.SUCCESS ? (
        <div>
          <p>Password changed successfully</p>
          <Button onClick={() => showMenu(true)}>Log in</Button>
        </div>
      ) : null}
      {status === STATES.ERROR ? (
        <div>
          <ErrorMessage>There was an error changing the password</ErrorMessage>
          <Button onClick={resetState}>Try again</Button>
        </div>
      ) : null}
    </div>
  );
};

export default ResetPasswordForm;
